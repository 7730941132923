var APIMapping = {
  /*************************** Onboarding API ******************************/
  register: "/signup ",
  setPassword: "/pswd/reset",
  sendOtp: "/otp/send",
  resendVerifyLink: "/link/send",
  login: "/login",
  logout: "/logout",
  verifyMobile: "/verifyMobile",
  resendLinkForResetPassword: "/pswd/resetlink",
  getAccessToken: "/accounts/refreshtoken",

  /*************************** Document API ******************************/
  uploadDocument: "/document/upload",
  getAllUploadedDocumentList: "/document/allDocs",
  deleteDocById: "/document/{document_id}",

  /*************************** Dashboard API ******************************/
  usersList: "/dashboard/userallDetails",
  usersDetails: "/dashboard/users/{startDate}/{endDate}",
  usersActions: "/dashboard/actions/{startDate}/{endDate}",
  topDevices: "/dashboard/topDeviceModels/{startDate}/{endDate}",
  usersBasedOnOs: "/dashboard/allDetails/{startDate}/{endDate}",
  getAllUsers: "/dashboard/userallDetails",
  getActivityGraphDetails: "/dashboard/activity/{time_frame}",
  getProcessedGraphDetails: "/dashboard/batch/processed-photos",
  getUsersPieChartDetails: "/dashboard/activity/statistics",
  getReportsAnalytics: "/dashboard/inviteTracker",

  /*************************** User Management API ******************************/
  addOrGetUser: "/users",
  userOptions: "/users/{user_id}",
  userActivation: "/users/activate/{user_id}",
  userDeactivation: "/users/deactivate/{user_id}",
  getUserDetails: "/userDetails",
  getLeftMenu: "/roles/menu",

  /*************************** Role Management API ******************************/
  addOrGetRole: "/roles",
  roleOptions: "/roles/{role_id}",
  allRoleTemplates: "/resources/templates",
  roleTemplates: "/resources/templates/{template_name}",

  /*************************** Face Identification API ******************************/
  uploadImage: "/photos/upload",
  resetAllUserData: "/user/data/deleteAll",
  resetUserByNumber: "/accounts/deleteUserByNumber",

  /*************************** Reports API ******************************/
  getAllUseReport: "/user/TotalReport/{userId}",

  /*************************** Upload file compress percentage ******************************/
  uploadPercentage: "/images/config",
  getUploadedPercentage: "/images/config/{userId}",
  upDatedUploadPercentage: "/images/config/{userId}",
  /*************************** FeedBack Dashboard  ******************************/
  feedbackDBData: "/feedback/{userId}",
  getAllFeedback: "/feedbackReport/all",
  getTotalReports: "/user/TotalReports/all",
  feedbackAllUseageDetails: "/feedback/report/allDetails",
};


const getKrakendApiMap = (name) => {
  const NODE_ENV = process.env.REACT_APP_NODE_ENV;
  
  //set BASE_URL
  const BASE_URL =
    NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT_API_BASE_URL
      : NODE_ENV === "testing"
      ? process.env.REACT_APP_TESTING_API_BASE_URL
      : process.env.REACT_APP_PRODUCTION_API_BASE_URL;

  return BASE_URL + "/api/v1" + APIMapping[name];
};

export default getKrakendApiMap;
